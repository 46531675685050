import fr from "vuetify/src/locale/fr"

export default {
  ...fr,
  locale: "Français",
  code: "fr",
  or: "ou",
  // Developement drawer menu & associated <h1> title
  welcome: "Bienvenue",
  home: "Accueil",
  multiposting: "Multidiffusion",
  about: "À propos",
  worker: "Personnel",
  worker_list: "Liste du personnel",
  order: "Commande",
  localization_examples: "Examples de localisation",
  clients_list: "Liste de clients",
  expand_menu: "Étendre le menu",
  collapse_menu: "Réduire",
  workers_list: "Liste du personnel",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "v2",
  logout: "Se déconnecter",
  user_settings: "Compte",
  change_space: "Navigation",
  all_spaces: "Tous les espaces",
  // Dispojob API request known errors
  api_errors: {
    401: "Informations d'authentification invalides, veuillez vous reconnecter", // Unauthorized
    403: "Vous n'avez pas la permission d'utiliser cette ressource", // Forbidden
    404: "La ressource demandée n'existe pas", // Unauthorized
    415: "Format de fichier non supporté", // Unsupported Media Type
    502: "Problème de connexion à l'API, veuillez réessayer ou contacter le support", // Bad Gateway, happened while restarting docker container
    500: "Erreur API, veuillez contacter le support", // Internal server error
    network: "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    "TypeError: Failed to fetch": "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    unknown: "Erreur inconnue, veuillez réessayer ou contacter le support",
    "User has no rights": "Vous n'avez pas accès à la ressource",
    "Invalid status": "Le statut est invalide",
    "User has no multiposting credentials": "Vous n'avez pas accès à l'application de multidiffusion",
    "Invalid multiposting credentials": "Votre identifiant ou mot de passe ne fonctionne pas. Vous pouvez contacter le support de Talentplug si le problème persiste",
    "Order quantity exceeded": "Vous ne pouvez pas dépasser la quantité de la commande",
    "message is missing": 'Le message est vide, merci de le remplir',
    "Order is not open": "Commande clôturée",
    "Order must have at least one period": "La commande doit avoir au moins une période enregistrée",
    "Profile document must be an image": "La photo de profil doit être une image",
    // Worker validation errors
    "Invalid birth_date (awaited format 'YYYY-MM-DD')": "Date de naissance invalide",
    "Invalid birth_date (wrong value)": "Date de naissance invalide",
    "Invalid birth_department": "Département de naissance invalide",
    "Invalid birth_city": "La longueur de la ville de naissance doit être comprise entre 2 et 255 caractères",
    "Invalid birth_country": "La longueur du pays de naissance doit être comprise entre 2 et 255 caractères", 
    "Invalid profile_picture": "Fichier de photo de profil invalide",
    "email or mobile_phone_number must be valid": "L'email ou le numéro de téléphone portable doit être valide",
    "Invalid agency": "Identifiant espace invalide",
    "Workers list fetch has failed": "Échec de la récupération de la liste du personnel",
    "Select all workers has failed": "Échec de la séléction de tous le personnel",
    "Worker insertion failed": "Échec de la création du personnel",
    "external_ref is too long to be registered": "La référence externe est trop longue",
    "external_ref already taken on space": "La référence externe est déjà prise sur l'espace",
    "OCR saving failed": "Échec de l'enregistrement des données OCR",
    "User is null": "Authentification utilisateur requise",
    // Phone number validation
    "Invalid phone_number": "Numéro de téléphone invalide",
    "Invalid mobile_phone_number": "Numéro de téléphone portable invalide",
    // Name validation
    "Invalid first_name": "La longueur du prénom doit être comprise entre 2 et 100 caractères",
    "Invalid last_name": "La longueur du nom doit être comprise entre 2 et 100 caractères",
    // Additional WorkerData validation errors
    "Invalid sex": "Civilité invalide",
    "Invalid postal_address": "La longueur de l'adresse postale doit être comprise entre 2 et 255 caractères",
    "Invalid postal_complement": "La longueur du complément d'adresse doit être comprise entre 2 et 255 caractères",
    "Invalid postal_code": "Code postal invalide",
    "Invalid postal_city": "La longueur de la ville doit être comprise entre 2 et 255 caractères",
    "Invalid postal_country": "La longueur du pays doit être comprise entre 2 et 255 caractères",
    "Invalid nationality": "La longueur de la nationalité doit être comprise entre 2 et 255 caractères",
    "Invalid family_situation": "Situation familiale invalide",
    "Invalid child_in_charge": "Le nombre d'enfants à charge est invalide",
    "Invalid wage_category": "Catégorie de paie invalide",
    "Invalid payement_category": "Moyen de paiement invalide",
    "Invalid introduction_text": "La longueur du texte d'introduction ne doit pas dépasser 65535 caractères",
  },
  // /workers page
  id: "Id",
  space_id: "Id espace",
  position_id: "Poste",
  workers: 'Personnels',
  profile: "Profil",
  civil_status: "État civil",
  nationality: "Nationalité",
  birth_city: "Lieu de naissance",
  birth_country: "Pays de naissance",
  birth_department: "Département de naissance",
  nir: "Numéro de sécurité sociale",
  family_situation: "Situation familiale",
  child_in_charge: "Enfants à charge",
  payment: "Paiement",
  bank_wage_category: "Catégorie de paie",
  bank_payement_category: "Moyen de paiement",
  bank_domiciliation: "Domiciliation",
  bank_beneficiary: "Bénéficiaire",
  bank_code: "Code Agence",
  bank_wicket_code: "Code Guichet",
  bank_account_number: "Numéro de compte",
  bank_key: "clé",
  created_on: "Créé le",
  last_modified_on: "Dernière modification le",
  dispojob_number: "Matricule Dispojob",
  external_reference: "Référence externe",
  from_space: "depuis l'espace",
  executive: "cadre",
  short_haul_drivers: "chauffeurs courte distance",
  teacher_contract_45: "contrat PROF +45ans",
  EEC_employee: "employé de la CEE",
  long_haul_driver: "grands routiers",
  executive_pension: "retraite cadre",
  executive_pension_65: "retraite cadre +65ans",
  employee_pension: "retraite employé",
  employee_pension_65: "retraite employé +65ans",
  payement_category: "Moyen de paiement",
  bank_transfer: "virement bancaire",
  bank_cheque: "chèque bancaire",
  // /clients page
  name: "Nom",
  // Pagination component
  pagination_title: "Données paginées",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Poste",
  customer: "Client",
  creation_date: "Date de création",
  starting_date: "Date de début",
  ending_date: "Date de fin",
  // Themes
  theme_light: "Thème clair",
  theme_dark: "Thème sombre",
  // 404 page
  not_found: "Cette page n'a pas été trouvée",
  not_found_text1: "Merci de nous faire part de cette erreur en cliquant sur le lien suivant: ",
  not_found_text2: "Accès au portail servicedesk",
  not_found_text3: "Vous avez reçu un lien pour créer votre compte sur le servicedesk. Nous traiterons cette demande dans les plus brefs délais. N'hésitez pas à faire une capture d'écran afin de nous aider à résoudre l'incident au plus vite.",
  // /login page
  login_page: {
    welcome_message: "Bienvenue sur votre espace privé Dispojob. L'accès à votre compte est confidentiel.",
    forgot_password: "Mot de passe oublié ?",
    login: "Connexion",
    log_in: "Se connecter",
    password: "Mot de passe",
    valid_password: "Mot de passe valide",
    min_char: "6 caractères au minimum",
    brochure: "Catalogue de formations en ligne",
    tutorial_access: "Accès aux tutoriels",
    discover_trainings: "Découvrir nos formations"
  },
  // /inscription page
  else:"ou",
  subscription_standard_full:"Dossier d'inscription complet",
  mobile_link:"Lien de l'application mobile",
  mobile_link_subtitle:"Partager le lien vers l'application mobile dispojob ou le QR code",
  subscription_express_cv:"Création express par dépôt de CV",
  subscription_standard_sms:"Partagez le lien vers votre dossier d'inscription en ligne, par SMS, en renseignant un numéro de portable.",
  subscription_express_sms:"Partagez le lien vers votre inscription express en ligne, par SMS, en renseignant un numéro de portable.",
  subscription_page: {
    error: {
      unknown: "Veuillez vérifier le formulaire en cliquant sur retour ou contacter l'agence pour finaliser votre inscription.",
      "Invalid email": "L'E-mail est invalide",
      "Invalid agency, Field agency_id is required": "L'espace est invalide, veuillez sélectionner un espace",
      "Field space_id is required": "Veuillez choisir un espace",
      "Invalid sex": "La Civilité est invalide",
      "Invalid first_name": "Le Prénom est invalid",
      "Invalid last_name": "Le Nom est invalide",
      "Invalid mobile_phone_number": "Le Numéro de téléphone portable est invalide",
      "Invalid postal_address": "L'Adresse postale est invalide",
      "Invalid postal_complement": "Le Complément d'adresse est invalide",
      "Invalid postal_code": "Le Code postal est invalide",
      "Invalid postal_city": "La Ville est invalide",
      "Invalid postal_country": "Le Pays est invalide",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "La Date de naissance est invalide",
      "Invalid birth_date (wrong value)": "La Date de naissance est invalide",
      "Invalid birth_city": "Le Lieu de naissance est invalide",
      "Invalid birth_country": "Le Pays de naissance est invalide",
      "Invalid nir (Pattern)": "Le numéro de sécurité sociale est invalide",
      "Invalid nir (Key)": "La clef de vérification de ce numéro de sécurité sociale est invalide",
      "email or mobile_phone_number already taken": "Email ou numéro de téléphone mobile déjà utilisé",
      "saving": "Erreur lors de l'inscription",
      "express" : ", vous allez être redirigé vers l'inscription classique",
      "Invalid favorite_jobs size": "Les métiers recherchés doivent comprendre entre 2 et 255 caractères.",
      "Invalid favorite_jobs type": "Les métiers recherchés ne peuvent pas être des nombres.",
      "Delete process fail": "La suppression à échouée"
    },
    favorite_jobs: "Métiers recherchés",
    non_compliant_file_error: "Erreur, fichier non conforme",
    error_no_space: "Erreur, Nous n'avons trouvé aucun espace correspondant au lien que vous avez saisi.",
    prepare_documents: "Préparez vos documents",
    mandatory_documents: "Document obligatoire",
    mandatory_resume: "Vous devez au moins fournir votre CV",
    documents_complete_file: "Document pour un dossier complet",
    your_resume: "Votre CV",
    resume: "CV",
    identity_document: "Pièce d'identité ",
    social_security_document: "Carte vitale ou attestation",
    residence_proof:" Justificatif de domicile",
    banque_identity: "RIB",
    work_certificate: "Certificat de travail",
    training_certificate: "Attestations de formation",
    medical_checkup: "Visite/aptitude médicale",
    documents_quality_info: "Vos documents et photos devront être de bonne qualité et bien cadrés.",
    documents_format_info: "Les fichiers téléversés doivent être d'une taille inférieur à 8 Mo et de format: .jpg, .jpeg, .png, .pdf, .doc, .docx, .odt ou .rtf",
    complete_file_warning: "Plus votre dossier est complet, plus vite vous aurez des chances de travailler.",
    file_usage:"En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de ma demande d'emploi, de la gestion de mon employabilité et de la relation contractuelle et/ou commerciale qui peut en découler.",
    sign_in:"M'inscrire",
    marital_status_info: "Les informations suivantes sont collectées afin de déclarer votre embauche en France. Si ce n'est pas votre cas, cliquez sur Continuer ",
    invalid_nir: "Ce numéro de sécurité sociale est invalide",
    invalid_nir_key: "La clef de vérification de ce numéro de sécurité sociale est invalide",
    other_id:"Autre pièce d'identité",
    other_documents: "Autre document",
    confirmation: {
      title: "Félicitations, c'est fait !",
      text_1: "Bravo, l'inscription est terminée.",
      text_2: "Votre employeur ou recruteur va vous contacter pour la suite du processus de votre recrutement.",
      text_3: "Bonnes chances et bon recrutement.",
      video_1: "Vidéo du fonctionnement",
      video_2: "1 Texto, 1 Boulot !",
      new_subscription: "Nouvelle inscription"
    },
    cet_consent: "Je souhaite ouvrir un compte épargne temps.",
    kiosk_subscription: "Inscription borne",
    portrait_guide: "Placez votre visage au centre de l'objectif",
    scanner_guide: "Placez le document sur le support du scanner",
    webcam_error: "Une erreur s'est produite. Veuillez vérifier les permissions d'accès à la webcam ou si cette dernière est utilisée par une autre source."
  },
  drag_and_drop: "Glissez / déposez",
  identity_card: "Carte d'identité",
  identity_card_verso: "Carte d'identité verso",
  passport: "Passeport",
  caces: "CACES",
  habilitation: "Habilitation",
  licence: "Licence",
  license: "Licence",
  diploma: "Diplôme",
  driver_licence: "Permis de conduire",
  other: "Autre",
  subscription: "Inscription",
  photo: "Photo",
  birth_date:'Date de naissance',
  required_field: "Champ requis",
  phone_or_email_required: "Téléphone ou Email requis",
  preparation: "Préparation",
  contact_details: "Coordonnées",
  marital_status: "État civil",
  documents: "Documents",
  employer: "Employeur",
  resident_permit: "Titre de séjour",
  confirmation: "Confirmation",
  continue: "Continuer",
  return: "Retour",
  // Calendar
  fullcalendar: "Calendrier Complêt",
  // /calendar page
  status: "Statut",
  available: "Disponible",
  unavailable: "Indisponible",
  unknown :"Inconnu",
  contract: "En contrat",
  blocked: "Bloqué",
  time_start: "Heure de début",
  time_end: "Heure de fin",
  event_created: "Évènement créé",
  event_updated: "Évènement mis à jour",
  event_deleted: "Évènement supprimé",
  fields: "Champs",
  names: "Nom, prénom",
  chronology: "Planning",
  postal_city_code: "Ville, code postal",
  // Parameters
  language: 'Langue',
  // Generic terms
  title: "Titre",
  create: "Créer",
  save: "Enregistrer",
  edit: "Modifier",
  delete: "Supprimer",
  update: "Mettre à jour",
  close: "Fermer",
  open: "Ouvrir",
  cancel: "Annuler",
  choose_space: "Choisir un espace",
  choose: "Choisir",
  space: "Espace",
  mandatory: "Obligatoire",
  optional: "Facultatif",
  required: "Requis",
  email: "E-mail",
  phone: "Téléphone",
  valid_email: "Adresse E-mail valide",
  invalid_email: "Adresse E-mail invalide",
  at_least_one_email: "Vous devez renseigner au moins une adresse e-mail",
  at_least_one_client: "Vous devez renseigner au moins un client",
  first_name: "Prénom",
  firstname: "Prénom",
  last_name: "Nom",
  lastname: "Nom",
  postal_address: "Adresse postale",
  postal_complement: "Complément d'adresse",
  postal_code: "Code postal",
  city: "Ville",
  country: "Pays",
  civility: "Civilité",
  mister: "Monsieur",
  misses: "Madame",
  start: "Démarrage",
  single: "Célibataire",
  in_couple: "En couple",
  married: "Marié(e)",
  divorced: "Divorcé(e)",
  widowed: "Veuf(ve)",
  civil_union: "Pacsé(e)",
  separated: "Séparé(e)",
  informations: "Informations",
  follow_up: "Suivi",
  from_the: "A partir du",
  until: "Jusqu'au",
  type: 'Type',
  busy: "Occupé",
  workers_in_error: "Personnel en erreur",
  // Availability
  availability: "Disponibilité",
  update_availability: "Modifier la disponibilité",
  save_availability_success: "La disponibilité a été ajoutée",
  update_availability_success:  "La disponibilité a bien été modifiée",
  save_availability_fail: "Votre opération à été annulée car une disponibilité est déjà présente. Merci de modifier la période.",
  delete_availability_success: "La disponibilité a bien été supprimée",
  delete_availability_fail: "Votre opération à été annulée car la disponibilité n'existe plus.",
  automatic_follow_up: "Suivi automatique",
  automated_follow_up: "Suivi automatisé",
  automatic_request_relaunch: "Relancer une demande automatique",
  information_on_the_availability: "Informations sur la disponibilité",
  select_status: "Sélectionner le statut",
  select_the_period: "Sélectionner la période",
  status_description: "Par défaut, le status disponible est sélectionné.",
  period_description: "Par défaut, les dates de début et de fin de période sont automatiquement remplies. La période démarre à l'enregistrement de cette action et se termine 1 semaine plus tard.",
  follow_up_description: "Par défaut, une demande de disponibilité sera envoyée automatiquement à la fin de la période. Vous pouvez la modifier ou la désactiver. La relance automatique est indisponnible pour le status 'En contrat'. ",
  success_register_availability: "La disponibilité a été enregistrée avec succès",
  "Worker already in contract": "Le personnel est déjà en contrat",
  // transport
  means_transport: "Moyen de locomotion",
  transportation_means: "Moyen de locomotion",
  car: "voiture",
  motorbike: "moto",
  bike_scooter: "vélo, trottinette",
  mass_transit: "transports en commun",
  moped: "scooter",
  walk: "à pied",
  carpool: "covoiturage",
  password: "Mot de passe",
  none: "aucune",
  space_name: "Nom de l'espace",
  user_name: "Nom de l'utilisateur",
  category: "Catégorie",
  invalid_size_array: "Vous ne pouvez pas ajouter plus de 3 métiers.",
  validate: "Valider",
  ok: 'OK',
  details: "Détails",
  // mobile phone number input
  phone_number: "Numéro de téléphone",
  mobile_phone_number: "Numéro de téléphone portable",
  invalid_mobile_phone_number: "Numéro de téléphone portable invalide",
  invalid_length: "La longueur du texte n'est pas valide",
  is_numeric: "Ce champ ne doit contenir que des nombres entiers",
  is_string: "Ce champ ne doit pas contenir de nombres",
  // Chronology
  week: "Semaine",
  month: "Mois",
  months: {0: "Janvier", 1: "Février", 2: "Mars", 3: "Avril", 4: "Mai", 5: "Juin", 6: "Juillet", 7: "Août", 8: "Septembre", 9: "Octobre", 10: "Novembre", 11: "Décembre"},
  date_from: "Du",
  date_to: "Au",
  morning: "Matin",
  evening: "Soir",
  night: "Nuit",
  previous_month: "Mois précédent",
  next_month: "Mois suivant",
  previous_week: "Semaine précédente",
  next_week: "Semaine suivante",
  previous_year: "Année précédente",
  next_year: "Année suivante",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Propriétaire",
    "space-users": "Administration",
    "jobs-delegate": "DPAE & Confirmation de mission",
    "orders": "Commandes",
    "multiposting": "Multidiffusion",
    order_template_temporary_work:"Commandes Intérim",
    order_template_permanent:"Commandes CDI",
    order_template_temporary: "Commandes CDD",
    order_template_individual_contractor_aggrements:"Commandes Vacation",
    order_template_subcontracting:"Commandes Sous-traitance",
  },
  permissions_descriptions: {
    "app-admin": "A tous les droits sur toute l'application",
    "space-users": "Vous êtes administrateur de votre Espace, vous pouvez créer des utilisateurs, réinitialiser leur mot de passe, etc...",
    "jobs-delegate": "A le droit d'utiliser la fonctionnalité DPAE & Confirmation de mission",
    "orders": "A le droit d'utiliser la fonctionnalité Commandes",
    "multiposting": "A le droit d'utiliser l'application Multidiffusion",
    order_template_temporary_work: "A le droit de créer des commandes Interim",
    order_template_permanent:"A le droit de créer des commandes CDI",
    order_template_temporary:"A le droit de créer des commandes CDD",
    order_template_individual_contractor_aggrements:"A le droit de créer des commandes Vacation",
    order_template_subcontracting:"A le droit de créer des commandes Sous-traitance",
  },
  // Admin
  admin: {
    title: "Propriétaire",
    users: {
      title: "Liste des utilisateurs",
    },
    "activity-logs": {
      title: "Journal d'activité"
    },
    statistics: {
      title: "Indicateurs"
    },
  },
  schema: "Table",
  user_id: "Id utilisateur",
  created_at: "Date de création",
  activity_logs_list:"Liste des journaux d'activité",
  // Statistics
  statistics: {
    title: "Indicateurs",
    spaces: "Espaces",
    year: "Année",
    month: "Mois",
    week: "Semaine",
    short_week: "S",
    please_select_one_space: "Veuillez choisir au moins un espace",
    select_all_spaces: "Sélectionner tous les espaces",
    download_xlsx: "Télécharger le fichier XLSX"
  },
  // Space administration page
  space_admin: {
    title: "Administration",
    users: {
      title: "Liste des utilisateurs",
    }
  },
  // User list
  user_list: "Liste des utilisateurs",
  permissions: "Permissions",
  spaces: "Espaces",
  groups: "Groupes",
  last_login_at: "Dernière connexion",
  v3_last_login_at: "Dernière connexion v3",
  deleted_at: 'Date de suppression',
  api_access: "Accès API",
  deleted_user: "Utilisateur supprimé",
  delete_users: "Supprimer les utilisateurs",
  deletion_successful: "Suppression effectuée avec succès",
  deletion_partial: "Suppression partiellement effectuée avec succès",
  user_warning_permission: "Attention ! Il n'est pas possible d'attribuer des permissions que vous ne possédez pas.",
  delegation: "démarrage",
  delegations: "démarrages",
  // User creation
  user_creation: "Créer un utilisateur",
  user_API: "Créer un utilisateur API ?",
  is_user_admin: "Est-ce que le futur utilisateur sera un administrateur ?",
  user_successfully_created: "Utilisateur créé avec succès",
  // User_update
  user_update: "Mettre à jour un utilisateur",
  v1_admin_rights: "Droits v1 EQUIPE DISPOJOB ?",
  user_successfully_updated: "Utilisateur mis à jour avec succès",
  // Paragon
  selection: "Sélection",
  select_page: "Sélectionner toute la page",
  select_all_filtered: "Sélectionner tous les résultats filtrés",
  select_file: "Selectionnez un fichier",
  list: "Liste",
  deleted_workers: "Archivés",
  asc: "Ascendant",
  desc: "Descendant",
  action: "Actions",
  filter: "Filtres",
  filtered: "Filtrés",
  search_with_filters: "Recherche avec filtres",
  total: 'Total',
  clear_filters: "Vider les filtres",
  search: "Rechercher",
  searches: "Rechercher par mots-clefs",
  no_data_available: "Aucune donnée disponible",
  empty_selection: "Sélection vide",
  // Date
  from: "À partir du",
  to: "Jusqu'au",
  select_only_one_date: "Vous n'avez sélectionné qu'une date.",
  filter_without_date: "Filtrer les résultats sans dates ?",
  do_you_want: "Voulez-vous",
  end_date_time: "Date et heure de fin",
  start_date_time: "Date et heure de début",
  // Worker List
  cv_preview: "Aperçu du CV",
  cv_preview_unsupported: "Ce navigateur ne supporte pas l'affichage des PDFs.",
  cv_preview_unsupported_file: "Ce format de fichier n'est pas supporté.",
  updated_at: "Date de mise à jour",
  confirm_delete_comment: "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
  warning: "Attention",
  confirm: "Confirmer",
  comment_deleted_successfully: "Commentaire supprimé avec succès",
  edit_comment: "Modifier le commentaire",
  comment_required: "Le commentaire est obligatoire",
  comment_updated_successfully: "Commentaire mis à jour avec succès",
  worker_list_searches_info: "La recherche porte sur les données suivantes : nom/prénom, ville, code postal, mobile, email, moyens de transport, statut, état, commentaire, métiers recherchés, attestations de formation, équipement",
  filter_by_first_last_name: "Filtrer par noms ou prénoms",
  separate_search_with_commas: "Séparez vos éléments de recherche (noms et prénoms) par des virgules pour une recherche multiple",
  card: "Fiches",
  recruitment: "Postes",
  load_all_results: "Charger tous les résultats",
  recruitment_total: "Nombre de postes",
  SMS_interaction: "Interactions SMS",
  SMS_interaction_last_date: "Date du dernier SMS",
  last_comment: "Dernier commentaire",
  comments: "Commentaires",
  comment:"Commentaire",
  comment_last_date: "Date du commentaire",
  web: "web",
  employee: "salarié",
  applicant: "postulant",
  "pre-registered": "pré-inscrit",
  "supplements OK": "compléments OK",
  candidate: "candidat",
  express: "express",
  state: "Etat",
  with:"Avec",
  without:"Sans",
  deactivate: "Désactiver",
  favorite_jobs: "Métiers recherchés",
  favorite_jobs_total: "Nombre de métiers recherchés",
  availabilities: "Disponibilités",
  missing_profile_picture: "Photo de profil manquante",
  profile_picture: "Photo de profil",
  create_worker: "Créer un personnel",
  update_state: "Modifier l'état",
  add_comment: "Ajouter un commentaire",
  success_add_comment: "Commentaire ajouté",
  remove_positions: "Dissocier",
  success_remove_positions: "Dissociation effectuée",
  success_add_positions: "Association effectuée",
  positions: "Postes",
  state_modified: "État modifié",
  worker_states: {
    web: "Web",
    candidate: "Candidat",
    employee: "Salarié",
    applicant: "Postulant",
    pre_registered: "Pré-inscrit",
    supplement_ok: "Compléments OK"
  },
  add_positions: "Associer",
  select_all_positions: "Tous les postes filtrés",
  availability_request: "Demander la disponibilité",
  availability_request_created: "Demande de disponibilité créée",
  archive: "Archiver",
  reactivate: "Réactiver",
  archive_confirm: "Êtes-vous sûr de vouloir archiver",
  reactivate_confirm: "Êtes-vous sûr de vouloir réactiver",
  success_archive: "Personnel archivé",
  success_archives: "Personnels archivés",
  success_reactivate: "Personnel réactivé",
  success_reactivates: "Personnels réactivés",
  formation_certificate: "Attestations de formation",
  equipment: "Équipement",
  medical_examination: "Visite médicale",
  passed: "Passée",
  future: "Future",
  is_sms_response: "Réponse SMS présente",
  sms_response: "Réponse SMS",
  sms_send: "Envoi SMS",
  "A progressif": "A progressif",
  "A direct": "A direct",
  A: "A",
  A1: "A1",
  B: "B",
  B1: "B1",
  C: "C",
  D: "D",
  "E(B)": "E(B)",
  "E(C)": "E(C)",
  "E(D)": "E(D)",
  has_phone: "Téléphone",
  sex: "Civilité",
  // Worker Form
  worker_successfully_created: "Personnel crée avec succès",
  worker_successfully_updated: "Personnel mis à jour avec succès",
  // OrderDetails
  order_information: "Informations de la commande",
  order_successfully_updated: "Commande mise à jour avec succès",
  no_modification_detected: "Aucune modification n'a été détectée",
  starting_date_less_than_ending_date: "La date de début doit être inférieure à la date de fin",
  ending_date_more_than_starting_date: "La date de fin doit être supérieure à la date de début",
  quantity_equal_or_greater_than_total: "La quantité doit être égale ou supérieure au nombre total de travailleurs déjà délégués. Si vous souhaitez abaisser cette quantité vous devez retirer du personnel déjà délégué.",
  address: "Adresse",
  pending: "En attente",
  processing: "Traitement",
  closed: "Clôturée",
  cancelled: "Annulée",
  validated_worker: "Personnel validé",
  order_period_successfully_deleted: "La période a été supprimée",
  order_period_deletion_confirmation_title: "Confirmation de suppression de la période",
  order_period_deletion_confirmation_text: "La suppression de la période entraînera la suppression de toutes les données associées à la période. Êtes-vous sûr ?",
  mission_proposal_sms: "vous propose un emploi. Cliquez sur ce lien pour lire le détail et donner votre réponse", // this string is preceded by a space name and followed by a link
  offer_the_job: "Proposer le job",
  order_address_position_required: "Pour vous servir de la géolocalisation merci de modifier l'adresse.",
  // OrderList
  order_list: {
    order_list: "Liste des commandes",
    follow_up: "Suivi",
    arrived: "Arrivé",
    contract_done: "Contrat fait",
    closed: "Clôturée",
    opened: "Ouverte",
  },
  follow_up_to_process: "À traiter",
  follow_up_start: "Démarrage",
  follow_up_contract_to_do: "Contrat à faire",
  follow_up_all: "Toutes",
  worker_names: "Personnel", // filter
  user_names: "Utilisateur", // filter
  started_at: "Démarrage", // filter
  finished_at: "Date de fin", // filter
  address_distance: "Distance",
  //OrderWorker
  periods: "Périodes",
  on_period: "sur la période",
  select_all_periods: "Toutes les périodes",
  orderWorker: "Commande de travailleurs",
  associated_with_position: "Associés au poste",
  not_associated_with_position: "Non-associés au poste",
  and_available: "& disponible",
  selected: "Sélectionnés",
  workable: "Matching de la disponibilité",
  matching_dispo: "Matching Dispo",
  add_to_order: "Ajouter à la commande",
  worker_already_in_contract: "Personnel déjà en contrat",
  already_in_contract: "est déjà en contrat",
  change_status: "Modifier le status",
  creation_success: "Création réussie",
  update_success: "Mise à jour réussie",
  choose_status: "Choisir un statut",
  update_status: "Mettre à jour",
  status_updated_at: "Date de mise à jour de disponibilités",
  comment_note: "Note à destination des utilisateurs de l'application mobile",
  warning_status: "Statut obligatoire",
  add: "Ajouter",
  availability_status_title: "Gestion de la disponibilité",
  availability_comment_title: "Gestion des Commentaires",
  availability_comment_limit: "Le commentaire ne doit pas dépasser 500 caractères",
  not_major: "-18 ans",
  sms_history: "historique des sms",
  send_sms: "Envoyer un SMS",
  count_sms: "SMS à envoyer",
  sms: "Rédiger votre message",
  gsm_count_question:'Comment est calculé la taille de mes messages ?',
  message_size_error: 'Message trop long',
  reminder_date_error:"La date de rappel doit être postérieure à la date d'envoi",
  gsm_count_explanation: {
    part_1:"si alphabet GSM standard => 1 de comptage / caractère.",
    part_2:"si caractère spéciale de l'alphabet GSM standard => 2 de comptage / caractère.",
    part_3:"si au moins 1 caractère différent des deux cas ci-dessus est détecté, on passe en unicode et nous comptons 2 pour chaque caractère quel qu'il soit."
  },
  set_reminder_date:"Programmer un rappel",
  send_date: "Date d'envoi",
  reminder_date: "Date de rappel (facultatif)",
  success_send_sms: "Envoi programmé",
  total_availability_requests: "Demande total de disponibilités",
  total_availability_response: "Réponse total de disponibilités",
  last_update_disponibility: "Dernière mise à jour de disponibilité",
  display_more: "Afficher plus",
  no_alerts: "aucune alerte",
  alerts_details: "détails des alertes",
  is_missing: "est manquant",
  response: "Réponse",
  send: "Envoi",
  to_send: "Envoyer",
  at:"chez",
  at_1:"à",
  on:"le",
  since: "Depuis le",
  tracking: "Notes Mémo",
  order_status: {
    select: "sélectionné",
    in_progress: "en cours de traitement",
    waiting: "en attente",
    accepted: "accepté",
    refused: "refusé",
    cancelled: "annulé"
  },
  order_worker_status: {
    unknown: "inconnu",
    available: "disponible",
    unavailable: "indisponible",
    blocked: "bloqué",
    contract: "en contrat",
    awaiting_reply: "en attente",
    pending: "en attente"
  },
  order_template_status: {
    select: "Sélection",
    in_progress: "En cours",
    waiting: "En attente",
    accepted: "Accepté",
    refused: "Refusé",
    cancelled: "Annulé",
    abandon: "Abandon"
  },
  info: "Info",
  // Order Form
  create_order: 'Créer une commande',
  client: 'Client',
  client_id: 'Client',
  position: 'Poste',
  step: 'Étape',
  quantity: 'Quantité',
  choose_client: "Choisir un client",
  choose_position: "Choisir un poste",
  order_successfully_created: "Commande créé avec succès",
  force_available_workers: "Matching dispo",
  force_available_workers_details: "Si coché, les personnels proposé auront tous le statut disponible sur la période de la commande",
  force_all_periods: "Toutes les périodes exigées",
  force_all_periods_details: "En cochant cette option, le personnel est obligé de prendre toutes les périodes ou aucunes",
  positive_number_required: "Nombre positif requis",
  order_priority:"Priorité",
  matching: "matching",
  free_text: "Texte libre",
  period: "Période",
  period_chronology_error: "Vous avez une erreur de chronologie dans ces périodes",
  contract_type: "Type de contrat",
  temporary_work: 'Intérim',
  permanent: 'CDI',
  temporary: 'CDD',
  training: 'Formation',
  individual_contractor_aggrements: 'Vacation',
  subcontracting: 'Sous-traitance',
  choose_type: "Choisir un type",
  blocked_period_choice: "Choix des périodes bloqué",
  // Activity log
  activity_logs: {
    title: "Titre",
    description: "Description",
    type: "Type",
    schema: "Catégorie",
    user_id: "Id utilisateur",
    created_at: "Date de création",
    create_success: "L'activité a été ajoutée",
    delete_success: "L'activité a été supprimée",
    new_subscription: "Nouvelle inscription d'un candidat",
    "Standard subscription": "Un candidat vient de s'inscrire à votre agence via l'inscription standard. Vous pouvez dès à présent accèder à son dossier.",
    "Kiosk subscription": "Un candidat vient de s'inscrire à votre agence via l'inscription borne. Vous pouvez dès à présent accèder à son dossier.",
    "Express subscription": "Un candidat vient de s'inscrire à votre agence via l'inscription express. Vous pouvez dès à présent accèder à son dossier.",
    user_login: "Connexion de l'utilisateur",
    user_login_description: "{0} s'est connecté à l'application",
  },
  // ACTION WORKERLIST
  cv: "CV",
  // SPACE SELECTION
  url_deactivate: "Inscription en ligne désactivée pour cet espace",
  url_express_deactivate: "Inscription Express en ligne désactivée pour cet espace",
  sms_deactivate: "Envoi de SMS désactivé pour cet espace",
  mobile_app_deactivate:"Application mobile désactivé pour cet espace",
  agency_code: "code agence",
  // SMS
  sms_sent: "SMS envoyé",
  phone_required:"Numéro de téléphone requis",
  // APP
  direct_download:"Téléchargement direct",
  // Multiposting
  multiposting_not_activated: "L'application Multidiffusion n'est pas encore activée sur votre compte.",
  talk_to_advisor: "Parlez à un conseiller",
  create_offer: "Créer une offre",
  create_offer_from_position: "Créer une offre à partir d'un poste",
  delete_offer_confirm: "Êtes-vous sûr de vouloir supprimer ?",
  offer_deleted: "Offre supprimée",
  view: "Voir",
  republish: "Republier",
  republishing: "Republication",
  unpublish: "Dépublier",
  offer_title: "Titre de l'offre",
  published_at: "Publiée le",
  jobboards: "Sites",
  offer_status: {
    Normal: "Publiée",
    Archivé: "Non publiée",
    Brouillon: "Brouillon"
  },
  offer_republish_success: "Republication enregistrée",
  offer_unpublish_success: "Dépublication enregistrée",
  offer_delete_success: "Offre supprimée",
  offer_updated_success: "Offre enregistrée",
  offer_no_data: "Vous n'avez pas d'offre",
  republication_yes: "oui, le",
  republication_no: "non",
  auto_republish_on: "Republication automatique activée",
  auto_republish_off: "Republication automatique désactivée",
  client_position: "Client / Poste",
  total_applicants: "Nb de postulants",
  offer_applicants: "Postulants à l'offre",
  deleted_applicant: "Personnel supprimé",
  // CV
  contacts: "Interlocuteurs",
  info_cv: 'Vous pouvez ajouter un email non-présent, en l\'écrivant et en appuyant sur la touche "entrée"',
  send_cv:"Envoyer le CV",
  response_to:"Répondre à",
  email_content:"Contenu de l'email",
  default_email_content:"<p>Bonjour,</p><p>Afin d'anticiper vos prochains besoins en recrutement, vous trouverez ci dessous les talents qui correspondent à vos critères.</p><p>Au plaisir d'en parler de vive voix ou de lire votre réponse.</p>",
  introduction_text: "Texte d'introduction",
  cv_selection_empty: "Aucun CV détecté pour vous permettre d'utiliser cette fonctionnalité.\nPour corriger cela, vous pouvez ajouter un CV dans le(s) dossier(s) du personnel concerné(s)",
  success_send_cv: "Envoi effectué",
  get_user_profile_error: "Erreur lors de la récupération du profil utilisateur",
  get_space_profile_error: "Erreur lors de la récupération du profil de l'espace",
  get_clients_error: "Erreur lors de la récupération des clients, veuillez réessayer",
  send_from_mailbox: "Envoyer depuis ma boîte email",
  // ENTRETIEN
  entretien: {
    title: 'Entretien',
    questionnaire: {
      title: "Questionnaire",
    },
    knowledge : {
      title: "Savoir",
      note: "connaissances, diplômes, attestations de formations, études, formations,stages."
    },
    know_how : {
      title: "Savoir-faire",
      note: "métier, expérience, compétences métier, matériels/logiciels utilisés."
    },
    life_skills : {
      title: "Savoir-être",
      note: "goûts, passions, loisirs, studies, activitéscollectives/individuelles."
    },
    how_to_become : {
      title: "Savoir devenir",
      note: "types de contrats, durées, salaires, projets professionnels,mobilité."
    },
    introduction_text : {
      title: "Texte d'introduction",
      note: "La présentation de ce personnel sera saisie automatiquement depuis l'action : Envoyer le CV."
    },
    resume_keywords : {
      title: "Mots clés",
      note: "Ce champ vous présente les mots clés extraits du CV."
    }
  },
  equipments: {
    title: "Equipement",
    data: {
      name: "Nom",
      comment: "Commentaire",
      size: "taille"
    },
    action: {
      add: "Ajouter un equipement",
      success_deleted: "Succès de la suppression d'équipements",
      success_add: "Succès de l'ajout d'équipements",
      success_update: "Succès de la modification d'équipements"
    }
  },
  position_search: "Métiers recherchés",
  // PREFERENCES
  start_preferences: {
    title: "Démarrage",
    data: {
      one_hour: 'Sous 1 heure',
      one_day: 'Sous 1 jour',
      one_week: 'Sous 1 semaine',
      one_month: 'Sous 1 mois',
      three_month: 'Sous 3 mois'
    }
  },
  environment_preferences: {
    title: "Environnement",
    data: {
      outdoor: 'Exterieur',
      indoor: 'Intérieur',
      cold: 'Froid',
      dust: 'Poussière',
      noise: 'Bruit',
      high: 'Hauteur',
      heavy_loads: 'Charges lourdes'
    }
  },
  availability_preferences: {
    title: "Disponibilité",
    data: {
      full_time: 'Temps plein',
      part_time: 'Temps partiel',
      day: 'Journée',
      morning: 'Matin',
      afternoon: 'Après-midi',
      night: 'Nuit',
      weekend: 'Weekend',
      '2_8_shift': 'Équipe 2*8',
      '3_8_shift': 'Équipe 3*8',
      '4_8_shift': 'Équipe 4*8',
      '5_8_shift': 'Équipe 5*8'
    }
  },
  can_note_here: 'Vous pouvez noter ici',
  force_delete_text: 'Du personnel a déjà été délégué sur la période, voulez-vous quand même la supprimer ?',
  worker_already_delegated: "Veuillez annuler l'affectation du personnel sur ces périodes avant de modifier les dates et heures, car ils ont déjà un contrat sur les créneaux concernés.",
  // Notifications
  notifications: "Notifications",
  show_unread: "Afficher uniquement les non-lues",
  mark_read: "Marquer comme lu",
  mark_unread: "Marquer comme non lu",
  mark_all_as_read: "Tout marquer comme lu",
  notification_from_the_last_30_days: "Voici toutes vos notifications des 30 derniers jours.",
  unread_notification_from_the_last_30_days: "Voici toutes vos notifications non-lues des 30 derniers jours.",
  today: "Aujourd'hui",
  yesterday: "Hier",
  older: "Plus ancien",
  go_to_order: "Aller sur la commande",
  go_to_profil: "Aller sur le profil",
  hidden_notification_types: {
    1: "Masquer les notifications d'acceptation de mission",
    2: "Masquer les notifications de refus de mission",
    3: "Masquer les notifications d'inscription",
    4: "Masquer les notifications de SMS reçu",
    5: "Masquer les notifications DISPONIBLE",
    6: "Masquer les notifications INDISPONIBLE",
  },
  hidden_accept_active_proposal: "uniquement les interlocuteurs acceptant la proposition active",
  closing_title: "Clôture d'une commande",
  closing_subtitle: "Sélectionner un état de clôture",
  confirm_closing_title: "Confirmation de clôture d'une commande",
  closing_confirmation: "Êtes vous sûr de vouloir clôturer la commande sélectionnée ?",
  to_close: "Clôturer",
  closing_success: "La commande a été clôturé avec succès",
  closing_error: "Erreur lors de la clôture de la commande",
  honored: "Honorée",
  partially_honored: "Partiellement honorée",
  not_honored: "Non honorée",
  choose_periods: "Choix des périodes",
  all_arrived: "Tous arrivés",
  closing_reasons: "Motif de la clôture",
  report: "Rapport",
  worker_contract_done: "Contrats du travailleur faits",
  all_contract_done: "Tous les contrats faits",
  closing_impossibility: "La commande ne peut être actuellement clôturée",
  report_data: {
    temporary_company: "Entreprise de travail temporaire",
    user_company: "Entreprise utilisatrice",
    sender: "Emetteur",
    first_period_date: "Heure de la première prise de poste",
    ratio_order_job: "Taux de conversion commande / prise de poste",
    ratio_contact_job: "Taux de conversion contact / prise de poste",
    first_job_accept_date: "Acceptation du 1er poste de la mission",
    completion_date: "Acceptation de tous les postes à pourvoir",
    start_date: "Date et heure de démarrage",
    worker_contacted: "Personne(s) Contactée(s)"
  },
  summary: "Récapitulatif",
  number_of_people_required: "Nombre de personnes demandées",
  begin_date: "Heure de démarrage",
  end_date: "Fin de mission",
  cycle: "cycle",
  weekly_duration: "Durée hebdomadaire",
  missions: "missions",
  further_information: "Complement d'informations",
  informations_convention_collective: "Informations Convention Collective",
  manager: "Responsable",
  anonymous_customer: "Client anonymisé",
  sms_validation: "Validation par SMS",
  display_city_in_sms:"Afficher la ville dans SMS",
  sms_notification: "Notification par SMS",
  mail_notification: "Notification par Email",
  reason_for_appeal: "Motif du recours",
  receipt: "Justificatif",
  source: "Source",
  tariff_coefficient: "Coefficient Tarifaire",
  business_manager: "Chargé d'affaire",
  // Documents
  recto_side: "Face recto",
  verso_side: "Face verso",
  document: "Document",
  document_name: "Nom du document",
  validity_date_start: "Date de début de validité",
  validity_date_end: "Date de fin de validité",
  card_number: "Numéro de carte",
  document_type: "Type de document",
  document_saved: "Document enregistré",
  document_deleted: "Document supprimé",
  delete_document_confirmation: "Êtes-vous sûr de vouloir supprimer ce document ?",
  document_component: {
    identity_documents: "Documents d'identité",
    carte_vital_or_certificate :"Carte vitale ou Attestation",
    visit_medical_aptitude:"Visite/aptitude médicale",
    training_certificates: "Attestations de formation",
    disability_situation: "Situation de handicap",
    btp_card: "Carte BTP",
    vaccination: "Vaccination",
    other_documents: "Autre documents",
    identity: "Identité",
    cv: "CV",
    photo: "Photo",
    profile: "Photo",
    passport: "Passeport",
    residence_permit: "Titre de séjour",
    family_booklet: "Livret de famille",
    residence_card: "Carte de séjour",
    foreign_identity: "Document d'identité étranger",
    active_proposal: "CV anonymisé",
    social_security: "Sécurité sociale",
    rib: "RIB",
    proof_of_address: "Justificatif domicile",
    medical_examination: "Visite médicale",
    work_certificate: "Certificat de travail",
    driving_licence: "Permis de conduire",
    fimo_fcos: "FIMO FCOS",
    other_certificate: "Autre attestation",
    caces: "CACES",
    habilitation: "Habilitation",
    diploma: "Diplôme",
    cdaph: "CDAPH",
    btp: "Carte BTP",
    vaccination_booklet: "Carnet de vaccination",
    card_number: "Numéro de carte",
    other: "Autres",
    contract: "Contrats",
    internal_document: "Documents internes",
    payslip: "Bulletins de salaire",
    timesheet: "Relevés d'heures",
    employer_attestation: "Attestations",
  },
  upload_the_document: "Déposer le document",
  detail_the_document: "Détailler le document",
  conditions_aptitudes: "Conditions / Aptitudes",
  chose_space_to_access_tags: "Veuillez sélectionner un espace pour accéder aux tags",
  worker_not_found: "Personnel non trouvé",
  reason_title: "Motif",
  hour_rate: "Taux horaire",
  coefficient: "Coefficient",
  reasons: {
    replacing_absent_employee: "Remplacement d'un salarié absent",
    replacement_before_delete_post: "Remplacement en cas de départ définitif d'un salarié précédant la suppression de poste",
    waiting_hire_CDI: "Attente d'embauche d'un salarié par CDI",
    complementary_professional_training: "Complément de formation professionnelle",
    other: "Autre",
    facilitating_recruitment_for_difficulties_unemployment: "Faciliter l'embauche de personne sans emploi rencontrant des difficultés sociales et professionnelle",
    not_employed_replacement: "Remplacement d'un travailleur non salarié",
    temporary_increase_activity: "Accroissement temporaire d'activité",
    "occasional,_specific_and_non-lasting_task": "Tache occasionnelle précis. définie et non durable",
    exceptional_export_order: "Commande exceptionnelle à l'exportation",
    urgent_work_required_for_safety_reasons: "Travaux urgents nécéssités par mesures de sécurité",
    facilitating_the_hiring_of_the_labour_code: "Faciliter l'embauche des personnes visées par l'art L1251-7 ali1",
    temporary_vacancy_of_the_Labour_Code: "Vacance temporaire d’un emploi dans les conditions prévues au 3° de l’art L1251-60  Code du Travail",
    constant_use: "Emploi d'usage constant",
    seasonal_employment: "Emploi à caractere saisonnier",
    training_course: "Formation",
    CIPI_training_contracts: "Contrats formation CIPI",
    CDPI_training_contracts: "Contrats formation CDPI",
    professionalisation_contract: "Contrat de professionnalisation",
    training_plan: "Plan de formation",
    qualification_contract: "Contrat de qualification"
  },
  contact: "Contact",
  choose_contact: "Choisir un contact",
  no_numbers_allowed: "Ne pas utiliser de chiffres",
  edit_contact: "Modifier le contact",
  create_contact: "Créer un contact",
  no_contact_found: "Aucun contact trouvé",
  contact_successfully_created: "Contact créé avec succès",
  contact_successfully_updated: "Contact modifié avec succès",
  invalid_data: "Données invalides",
  clear_contact: "Effacer le contact",
  user: "Utilisateur",
  choose_user: "Choisir un utilisateur",
  warning_user_not_found: "L'utilisateur affilié à la commande n'est pas présent sur cette agence. l'utilisateur connecté a été sélectionné par défaut",
  contact_need_mail_or_phone: "Un contact doit avoir un mail ou un numéro de téléphone",
  active_proposal: "Proposition active",
  remote_prospecting: "Télé prospection",
  field_prospecting: "Prospection terrain",
  word_of_mouth: "Bouche-à-oreille",
  events: "Evénements",
  source_web: "Internet",
  schedules: "Horaire de la mission",
  choose_schedule: "Choisir un Horaire",
  working_time: "Durée hebdomadaire",
  collective_agreement_pay_scale: "Niveau/Position/Coefficient (convention collective)",
  task: "Tâche à accomplir",
  complementary_information: "Complément d'informations",
  invalid_float: "Cette valeur doit être comprise entre 0 et 1000",
  choose_task: "Selectionner ou créer des tâches",
  proof: "Justificatif",
  choose_reasons: "Choisir un motif",
  hour_rate_label: "Taux horaire",
  reason_label: "Motif",
  choose_reason: "Choisir un motif",
  invalid_quantity: "Cette valeur doit être supérieure ou égale à 1",
  mandatory_fields: "Champs obligatoires",
  search_contact: "Rechercher un contact",
  resort: "Recours",
  conditions: "Conditions",
  user_requirement: "Besoin utilisateur",
  user_does_not_have_access_to_the_order_template: "Veuillez choisir un type de contrat",
}