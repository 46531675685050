import axios from "axios"
import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { state, mutations } from "@/plugins/state"
import { hasPermission } from "@/plugins/token"

Vue.use(VueRouter)

// NOTE - Routes which require auth must have a "meta.relaxed = false" key
const routes: Array<RouteConfig> = [
  {
    path: "/login",
    alias: "/",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/multiposting",
    name: "Multiposting",
    component: () => import(/* webpackChunkName: "multiposting" */ "@/views/Multiposting.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/Administration.vue"),
    children: [
      {
        path: 'activity-logs',
        name: "AdminActivityLogs",
        component: () => import(/* webpackChunkName: "admin/activity-logs" */ "@/views/admin/ActivityLogs.vue"),
        meta: {
          relaxed: false, // does require auth
        }
      },
      {
        path: 'users',
        name: "AdminUsers",
        component: () => import(/* webpackChunkName: "admin/users" */ "@/views/admin/Users.vue"),
        meta: {
          relaxed: false, // does require auth
        },
        children: [{
          path: ':id([0-9]+)',
          name: "AdminUserUpdate",
          props: true,
          component: () => import(/* webpackChunkName: "admin/users/update/:id([0-9]+)" */ "@/components/admin/UserFormUpdate.vue"),
          meta: {
            relaxed: false, // does require auth
          }
        }]
      },
      {
        path: 'statistics',
        name: "AdminStatistics",
        component: () => import(/* webpackChunkName: "admin/statistics" */ "@/views/admin/Statistic.vue"),
        meta: {
          relaxed: false, // does require auth
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (hasPermission("app-admin")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    redirect: () => {
      return { path: '/admin/users' }
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/space",
    name: "SpaceAdministration",
    component: () => import(/* webpackChunkName: "space" */ "@/views/space/Administration.vue"),
    children: [{
      path: 'users',
      name: 'SpaceUsersAdministration',
      component: () => import(/* webpackChunkName: "space" */ "@/views/space/UsersAdministration.vue"),
      meta: {
        relaxed: false, // does require auth
      },
      children: [{
        path: ':id([0-9]+)',
        name: "SpaceUserUpdate",
        props: true,
        component: () => import(/* webpackChunkName: "space/users/update/:id([0-9]+)" */ "@/components/space/UserFormUpdate.vue"),
        meta: {
          relaxed: false, // does require auth
        }
      }]
    }],
    beforeEnter: (to, from, next) => {
      if (hasPermission("space-users")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    redirect: () => {
      return { path: '/space/users' }
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/admin/users/create',
    name: "AdminUserCreate",
    component: () => import(/* webpackChunkName: "admin/users/create" */ "@/views/UserForm.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("app-admin")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/space/users/create',
    name: "SpaceUserCreate",
    component: () => import(/* webpackChunkName: "space/users/create" */ "@/views/UserForm.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("space-users")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/admin/users/update/:id([0-9]+)',
    name: "AdminMobileUserUpdate",
    component: () => import(/* webpackChunkName: "admin/users/update/:id([0-9]+)" */ "@/components/admin/UserFormUpdate.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("app-admin")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/space/users/update/:id([0-9]+)',
    name: "SpaceMobileUserUpdate",
    component: () => import(/* webpackChunkName: "space/users/update/:id([0-9]+)" */ "@/components/space/UserFormUpdate.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("space-users")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/workers/update/:id([0-9]+)',
    name: "MobileWorkerUpdate",
    component: () => import(/* webpackChunkName: "workers/update/:id([0-9]+)" */ "../components/worker/WorkerTabsComponent.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/account",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/Menu.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/workers",
    name: "Workers",
    alias: "/future-workers",
    component: () => import(/* webpackChunkName: "workers" */ "@/views/workers/Workers.vue"),
    meta: {
      relaxed: false, // does require auth
    },
    children: [{
      path: ':id([0-9]+)',
      name: "WorkerUpdate",
      props: true,
      component: () => import(/* webpackChunkName: "workers/update/:id([0-9]+)" */ "../components/worker/WorkerTabsComponent.vue"),
      meta: {
        relaxed: false, // does require auth
      }
    }]
  },
  {
    path: "/workers/create",
    name: "WorkerCreate",
    component: () => import(/* webpackChunkName: "workers" */ "@/views/workers/WorkerCreation.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/orders/create',
    name: "OrderCreate",
    component: () => import(/* webpackChunkName: "orders/create" */ "@/views/OrderForm.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("orders")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/orders',
    name: "OrderList",
    component: () => import(/* webpackChunkName: "orders/create" */ "@/views/OrderList.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("orders")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/orders/:id([0-9]+)',
    name: "Order",
    component: () => import(/* webpackChunkName: "orders/:id([0-9]+)" */ "@/views/Order.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("orders")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: '/orders/:id([0-9]+)/:order_template_status_id([0-9]+)',
    name: "OrderTemplate",
    component: () => import(/* webpackChunkName: "orders/:id([0-9]+)" */ "@/views/Order.vue"),
    beforeEnter: (to, from, next) => {
      if (hasPermission("orders")) next()
      // reject the navigation
      else next({name: "Not found"})
    },
    meta: {
      relaxed: false, // does require auth
    }
  },
  {
    path: "/inscription/:agencySubDomain(.*)",
    name: "Subscription",
    component: () => import(/* webpackChunkName: "subscription" */ "@/views/Subscription.vue")
  },
  {
    path: "/express/:agencySubDomain(.*)",
    name: "SubscriptionExpress",
    component: () => import(/* webpackChunkName: "express" */ "@/views/SubscriptionExpress.vue")
  },
  {
    path: "/inscription-borne/:agencySubDomain(.*)",
    name: "KioskSubscription",
    component: () => import(/* webpackChunkName: "subscription" */ "@/views/KioskSubscription.vue")
  },
  { 
    path: '/:pathMatch(.*)*',
    name: "Not found",
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
    meta: {
      relaxed: false, // does require auth
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

// Catch all route request
router.beforeEach((to, from, next) => {
  // We verify that:
  // * the route from which we come is the entry point to the application
  // * the route from which we go is not relaxed (otherwise we don't need to do this verification)
  // * there is no token
  // We do this check to be sure to run this token test only once when entering the application.
  if (from === VueRouter.START_LOCATION && to.meta?.relaxed === false && state.token !== false) {
  // if (from.path === "/" && from.name === null && !to.meta?.relaxed && state.token !== false) {
    // If the condition is passed we use the token registered in the localstorage to update the permissions.
    // This allows us to check the validity of the token.
    axios
      .get("/v1/users/permissions")
      .catch(() => {
        // If the token is wrong the user will be disconnected, the token and the localstorage permissions will be deleted.
        next({name: "Login"})
        mutations.logout()
      })
  }
  if (to.matched.some((record) => record.meta.relaxed !== false)) {
    next() // does not require auth, make sure to always call next()!
  // this route requires auth, check if logged in
  // if not, redirect to login page.
  } else if (state.token === false) {
    next({ name: "Login" })
  } else {
    next() // go to wherever I'm going
  }
})

export default router
