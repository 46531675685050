
import Vue from "vue"
import { AxiosError } from "axios"

export interface SnackBarData {
  message?: string,
  axiosError?: AxiosError,
  color?: string,
  timeout?: number,
  multiLine?: boolean,
  structuredMessage?: {
    title?: string,
    items: string[]
  }
}

/**
 * A snackbar component to display error/warning/info type message to the user
 */
export default Vue.extend({
  name: 'Snackbar',
  props: {
      data: Object,
  },
  data: function () {
      return {
        text: '',
        visible: false,
        color: '',
        currentProgressTime: 0, // time the progress bar has been progressing in ms
        timeout: 5000, // time before the snackbar disappears (default 5s, 5000ms)
        inProgress: false,
        structuredMessage: false,
        structuredMessageTitle: '',
        structuredMessageItems: [] as string[]
      }
  },
  watch : {
    data () {
      if (this.data.axiosError) {
        this.getErrorMessage()
        this.color = 'error'
        this.structuredMessage = false
      }
      else if (this.data.structuredMessage) {
        // Handle structured message format
        this.structuredMessage = true
        this.structuredMessageTitle = this.data.structuredMessage.title || ''
        this.structuredMessageItems = this.data.structuredMessage.items || []
      }
      else if (this.data.message) {
        this.text = this.data.message
        this.color = 'success'
        this.structuredMessage = false
      }

      if (this.data.color) {
        this.color = this.data.color
      }

      // Use custom timeout if provided
      this.timeout = this.data.timeout || 5000

      let snackbarWrapper = (this.$refs.snackbar as Vue).$el.lastChild as HTMLElement
      if (snackbarWrapper.style.display === "none") {
        // Only trigger the progress bar when the snackbar appears (prevents weird behaviors)
        this.inProgress = true
        this.syncProgbar()
      }
      this.visible = true
    },
  },
  mounted() {
    // adding mouse enter/leave events on an element incased in a vuetify component (the actual snackbar)
    let snackbarWrapper = (this.$refs.snackbar as Vue).$el.lastChild as HTMLElement
    snackbarWrapper.addEventListener('mouseenter', () => this.resetProgbar(), false)
    snackbarWrapper.addEventListener('mouseleave', () => this.restartProgBar(), false)
  },
  methods: {
    getErrorMessage(): void {
      this.text = this.$vuetify.lang.t('$vuetify.api_errors.unknown') // unknown error by default
      if (this.data.axiosError.response) { // client received an error response (5xx, 4xx)
        if (this.$vuetify.lang.t("$vuetify.api_errors." + this.data.axiosError.response.status) !== "$vuetify.api_errors." + this.data.axiosError.response.status) { // try to localize error by its status code
          this.text = this.$vuetify.lang.t("$vuetify.api_errors." + this.data.axiosError.response.status)
        } else if (this.$vuetify.lang.t("$vuetify.api_errors." + this.data.axiosError.response.data) !== "$vuetify.api_errors." + this.data.axiosError.response.data) { // try to localize error by its body message
          this.text = this.$vuetify.lang.t("$vuetify.api_errors." + this.data.axiosError.response.data)
        }
      } else if (this.data.axiosError.request) { // client never received a response, or request never left (API container down)
        if (this.data.axiosError.request.response === '"Space has no rights"') {
          this.text = this.$vuetify.lang.t('$vuetify.sms_deactivate')
        } else {
          this.text = this.$vuetify.lang.t('$vuetify.api_errors.network')
        }
      }
    },
    syncProgbar(): void {      
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //If our current time is larger than the timeout
        if ((this.timeout <= this.currentProgressTime) || !this.inProgress) {    
            this.currentProgressTime = 0 // reset the current time
            this.inProgress = false
        } else {
          //Increment the time counter by 100
          this.currentProgressTime += 100
          //Recursivly update the progress bar
          this.inProgress = true
          this.syncProgbar()
        }
      }, 100)
    },
    resetProgbar(): void {
      this.currentProgressTime = 0 // reset the progress of the progressbar
      this.inProgress = false
    },
    restartProgBar(): void {
      this.inProgress = true
      this.syncProgbar() //restart the progress of the progressbar
    },
    closeSnackbar(): void {
      this.visible = false // hide the snackbar
      this.timeout = 0 // closes the snackbar and progress bar advancement
    }
  }
})
