
import DateFormatMixin from "@/mixins/DateFormatMixin.vue"
import { mutations, state } from "@/plugins/state"
import axios from "axios"
import { format } from "date-fns"
import Vue, { VueConstructor } from "vue"
import NotificationItem from "./NotificationItem.vue"

type NotificationData = {
  [key: string]: number|string
}

interface Notification {
  id: number,
  type: number,
  read: boolean,
  created_at: string,
  title: string,
  message: string,
  data: NotificationData,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: "Notification",
  mixins: [DateFormatMixin],
  components: {
    NotificationItem
  },
  data () {
    return {
      notifications: [] as Notification[],
      loadedIds: new Set(),
      onlyUnreadFilter: state.showUnreadNotification,
      displayNotifications: false,
      displayOptions: false,
      timer: 0,
      hiddenNotifications: state.hiddenNotificationTypes as string[],
      markAllAsReadLoading: false,
    }
  },
  computed: {
    /**
     * Calculate the best virtual scroller height
     */
    getScrollerHeight: function() {
      return (this.$vuetify.breakpoint.height - 80) < 500 ? (this.$vuetify.breakpoint.height - 80) + 'px' :  '500px'
    },
    /**
     * Calculate total of unread notifications, displayed or not
     */
    unread: function(): number {
      let unreadCount = 0
      for (const notification of this.notifications) {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          continue
        }
        if (!notification['read']) {
          unreadCount++
        }
        if (unreadCount === 10) {
          break
        }
      }
      return unreadCount
    },
    displayedNotifications: function(): Notification[] {
      // Apply filters
      let filteredNotifications =  this.notifications.filter((notification: Notification) => {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          return false
        }
        if (this.onlyUnreadFilter) {
          return notification.read === false
        }
        return true
      })
      // add fake item for "end of notifications" message display
      filteredNotifications.push({
          "id": -1,
          "type": -1,
          "read": false,
          "created_at": "",
          "title": "",
          "message": "fake item for end of notifications display",
          "data": {}
      })
      // return
      return filteredNotifications
    }
  },
  mounted() {
    this.getNotifications()
    this.timer = setInterval(() => {
      this.getNotifications(true)
    }, 5000)
  },
  watch: {
    onlyUnreadFilter (val) {
      mutations.setShowUnreadNotification(val)
    },
    hiddenNotifications: {
      handler(){
        mutations.setHiddenNotificationTypes(this.hiddenNotifications)
      },
     deep: true
  }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    readNotification(notification: Notification) {
      // prevent failure on fake last item
      if (notification.id === -1) {
        return
      }

      axios.put(`/v1/notifications/${notification.id}`,
        {
          read: !notification.read
        }
      )
      .then(() => { // HTTP status between 200 & 299
        notification.read = !notification.read // reflect success on front data
      })
    },
    markAllAsRead() {
      // Prevent multiple clicks by checking the loading state
      if (this.markAllAsReadLoading !== false) {
        return
      }
      this.markAllAsReadLoading = true

      // Split notifications into chunks of 100
      const notificationChunks = this.chunkNotifications(this.displayedNotifications, 100)

      const updateNotificationBatch = (chunk: Notification[]) => {
        let body = {} as {[key: number]: {read: boolean}}

        // Build body for the current batch
        chunk.forEach(notification => {
          if (notification.id !== -1) {
            body[notification.id] = { read: !notification.read }
          }
        })

        // Send request to mark notifications as read
        return axios.put(`/v1/notifications`, body)
          .then((response) => {
            let errorIds = Object.keys(response.data)

            // Update notifications on success (excluding errors)
            chunk.forEach(notification => {
              if (
                notification.id !== -1 &&
                !errorIds.includes(String(notification.id)) &&
                notification.id in body
              ) {
                notification.read = body[notification.id].read
              }
            })
          })
      }

      // Process batches sequentially
      const processBatches = async () => {
        for (const chunk of notificationChunks) {
          await updateNotificationBatch(chunk)
        }
      }

      // Call the batch processing
      processBatches()
        .finally(() => {
          this.markAllAsReadLoading = false
        })
    },
    // Helper function to chunk notifications into batches of a given size
    chunkNotifications(array: Notification[], size: number) {
      const chunks = []
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size))
      }
      return chunks
    },
    getNotifications(refresh = false) {
      let url = '/v1/notifications'
      if (refresh) {
        // We set the filter 5 seconds from now
        const date = new Date(Date.now() - 5000)
        const dateTimeString = format(date, 'yyyy-MM-dd HH:mm:ss')
        url += `?from=${dateTimeString}`
      }
      axios.get(url)
      .then(response => { // HTTP status between 200 & 299
        if (response.data.length > 0) {
          // Create a set to keep track of unique notification IDs
          let newNotifications: Notification[] = []
          response.data.forEach((notification: Notification) => {
            if (this.loadedIds.has(notification.id) === false) {
              this.loadedIds.add(notification.id) // add id to Set
              newNotifications.push(notification)
            }
          })
          // Add new notifications at the beginning of the list
          this.notifications = newNotifications.concat(this.notifications)
        }
      })
    }
  }
})
